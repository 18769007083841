/* Card Animations */
.animated-card {
  background-color: var(--light-gray);
  border-radius: 8px;
  transform: translateY(0);
  transition: all 0.3s ease;
  cursor: pointer;
  border: 2px solid var(--primary-blue);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 1.5rem;
}

.animated-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.15), 0 0 15px rgba(33, 150, 243, 0.5);
  background-color: white;
}

/* Stats Animation */
.animated-stat {
  text-align: center;
  padding: 2rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.animated-stat:hover {
  transform: scale(1.05);
  background-color: var(--primary-blue);
  box-shadow: 0 0 20px rgba(33, 150, 243, 0.5);
  border: 1px solid var(--primary-blue);
}

.animated-stat:hover h3,
.animated-stat:hover p {
  color: white;
}

/* Timeline Animation */
.animated-timeline-item {
  margin-bottom: 2rem;
  padding: 1.5rem 2rem;
  border: 2px solid var(--primary-blue);
  border-left: 4px solid var(--primary-blue);
  transition: all 0.3s ease;
  cursor: pointer;
  border-radius: 8px;
  background-color: var(--light-gray);
}

.animated-timeline-item:hover {
  transform: translateX(10px);
  background-color: white;
  border-left: 4px solid var(--primary-blue);
  box-shadow: 0 0 15px rgba(33, 150, 243, 0.3);
}

/* Tab Button Animation */
.animated-tab {
  padding: 1rem 2rem;
  background-color: transparent;
  color: var(--dark-gray);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  margin: 0 0.5rem;
}

.animated-tab:hover:not(.active) {
  background-color: rgba(33, 150, 243, 0.1);
}

.animated-tab.active {
  background-color: var(--primary-blue);
  color: white;
}

/* Media Card Animation */
.animated-media-card {
  background-color: var(--light-gray);
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.animated-media-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0,0,0,0.15), 0 0 15px rgba(33, 150, 243, 0.3);
}

/* Event Card Animation */
.animated-event-card {
  background: linear-gradient(135deg, white, rgba(2, 106, 186, 0.05));
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border: 2px solid var(--primary-blue);
  position: relative;
  margin-bottom: 2rem;
}

.animated-event-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0,0,0,0.15), 0 0 15px rgba(33, 150, 243, 0.3);
  background: linear-gradient(135deg, white, rgba(1, 42, 74, 0.1));
}

.animated-event-card > div {
  position: relative;
  z-index: 1;
  background: transparent;
  border-radius: 8px;
}

/* Add styles for past events */
.animated-event-card.past-event {
  background: linear-gradient(135deg, #f5f5f5, rgba(1, 42, 74, 0.02));
}

.animated-event-card.past-event:hover {
  background: linear-gradient(135deg, #f5f5f5, rgba(1, 42, 74, 0.05));
}

/* Add styles for the tab buttons container */
.event-tabs {
  background: linear-gradient(135deg, rgba(1, 42, 74, 0.05), rgba(1, 42, 74, 0.1));
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  text-align: center;
}

/* Style the section container */
.events-container {
  background: linear-gradient(135deg, rgba(1, 42, 74, 0.02), rgba(1, 42, 74, 0.05));
  padding: 2rem;
  border-radius: 12px;
  margin: 2rem 0;
}

/* Image Gallery Animations */
@keyframes glowBorder {
  0% {
    box-shadow: 0 0 15px rgba(33, 150, 243, 0.5);
    border-top: 2px solid var(--primary-blue);
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
  }
  25% {
    box-shadow: 5px 0 15px rgba(33, 150, 243, 0.5);
    border-top: 2px solid transparent;
    border-right: 2px solid var(--primary-blue);
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
  }
  50% {
    box-shadow: 0 5px 15px rgba(33, 150, 243, 0.5);
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    border-bottom: 2px solid var(--primary-blue);
    border-left: 2px solid transparent;
  }
  75% {
    box-shadow: -5px 0 15px rgba(33, 150, 243, 0.5);
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-left: 2px solid var(--primary-blue);
  }
  100% {
    box-shadow: 0 0 15px rgba(33, 150, 243, 0.5);
    border-top: 2px solid var(--primary-blue);
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
  }
}

@keyframes zoomPulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

.gallery-card {
  background-color: var(--light-gray);
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.4s ease;
  position: relative;
  border: 2px solid transparent;
  cursor: pointer;
}

.gallery-card:hover {
  animation: glowBorder 2s linear infinite;
  transform: translateY(-5px);
}

.gallery-card img {
  transition: all 0.5s ease;
}

.gallery-card:hover img {
  animation: zoomPulse 2s ease-in-out infinite;
}

.gallery-card .content {
  padding: 1rem;
  background: linear-gradient(to top, rgba(33, 150, 243, 0.1), transparent);
  transition: all 0.3s ease;
}

.gallery-card:hover .content {
  background: linear-gradient(to top, rgba(33, 150, 243, 0.2), transparent);
}

.gallery-card h3 {
  color: var(--primary-blue);
  transition: all 0.3s ease;
}

.gallery-card:hover h3 {
  transform: translateX(10px);
}

/* Modal Animation */
@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.gallery-modal {
  animation: modalFadeIn 0.3s ease-out forwards;
}

.gallery-modal img {
  transition: transform 0.3s ease;
}

.gallery-modal:hover img {
  transform: scale(1.02);
}

/* Sticky Navigation */
.sticky-nav {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  z-index: 1000;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}

.sticky-nav.hide {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-50%) translateX(100%);
}

@keyframes stickyHover {
  0% {
    transform: translateX(0) rotate(0deg);
    box-shadow: 0 4px 15px rgba(33, 150, 243, 0.2);
  }
  50% {
    transform: translateX(-5px) rotate(-2deg);
    box-shadow: -5px 4px 15px rgba(33, 150, 243, 0.4);
  }
  100% {
    transform: translateX(0) rotate(0deg);
    box-shadow: 0 4px 15px rgba(33, 150, 243, 0.2);
  }
}

.sticky-nav-item {
  background: linear-gradient(135deg, var(--primary-blue), var(--secondary-blue));
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  position: relative;
  border-radius: 4px 0 0 4px;
  box-shadow: 0 4px 15px rgba(33, 150, 243, 0.2);
  writing-mode: vertical-lr;
  transform-origin: right center;
}

.sticky-nav-item::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 4px;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transition: all 0.3s ease;
}

.sticky-nav-item:hover {
  animation: stickyHover 1s ease-in-out infinite;
}

.sticky-nav-item:hover::before {
  width: 8px;
  background: rgba(255, 255, 255, 0.5);
}

@media (max-width: 768px) {
  .sticky-nav {
    top: auto;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
    flex-direction: row;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    border-radius: 12px 12px 0 0;
    box-shadow: 0 -4px 15px rgba(0, 0, 0, 0.1);
  }

  .sticky-nav-item {
    writing-mode: horizontal-tb;
    border-radius: 4px;
    padding: 0.75rem 1.5rem;
  }
}

/* Video Gallery Animations */
.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

@keyframes playButtonPulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.9;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.9;
  }
}

.video-card {
  background-color: var(--light-gray);
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.4s ease;
  position: relative;
  border: 2px solid transparent;
}

.video-card:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 0 10px 20px rgba(33, 150, 243, 0.2);
  border-color: var(--primary-blue);
}

.thumbnail-container {
  position: relative;
  overflow: hidden;
}

.video-thumbnail {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: all 0.5s ease;
}

.video-card:hover .video-thumbnail {
  transform: scale(1.1);
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background: rgba(33, 150, 243, 0.9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  opacity: 0;
  transition: all 0.3s ease;
}

.play-button svg {
  width: 30px;
  height: 30px;
}

.video-card:hover .play-button {
  opacity: 1;
  animation: playButtonPulse 2s ease-in-out infinite;
}

.video-info {
  padding: 1.5rem;
  background: linear-gradient(to top, rgba(33, 150, 243, 0.1), transparent);
  transition: all 0.3s ease;
}

.video-card:hover .video-info {
  background: linear-gradient(to top, rgba(33, 150, 243, 0.2), transparent);
}

.video-info h3 {
  color: var(--primary-blue);
  margin-bottom: 0.5rem;
  transition: transform 0.3s ease;
}

.video-card:hover .video-info h3 {
  transform: translateX(10px);
}

.video-description {
  color: var(--dark-gray);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.video-date {
  color: var(--dark-gray);
  font-size: 0.875rem;
  opacity: 0.8;
}

/* Video Modal Animations */
@keyframes modalOverlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modalContentSlideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.video-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: modalOverlayFadeIn 0.3s ease-out;
}

.video-modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 900px;
  position: relative;
  animation: modalContentSlideIn 0.4s ease-out;
}

.video-modal-close {
  position: absolute;
  right: -1.5rem;
  top: -1.5rem;
  background: var(--primary-blue);
  border: none;
  color: white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.video-modal-close:hover {
  transform: rotate(90deg);
  background: var(--secondary-blue);
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-bottom: 1.5rem;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 4px;
}

.video-modal-info {
  color: var(--dark-gray);
}

.video-modal-info h3 {
  color: var(--primary-blue);
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .video-modal-content {
    width: 95%;
    padding: 1rem;
  }
  
  .video-modal-close {
    right: 0.5rem;
    top: 0.5rem;
    width: 30px;
    height: 30px;
    font-size: 1.2rem;
  }
}

/* Social Media Animations */
.social-tabs {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  position: relative;
  flex-wrap: wrap;
  background: linear-gradient(135deg, rgba(1, 42, 74, 0.05), rgba(2, 106, 186, 0.1));
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(1, 42, 74, 0.05);
}

.social-content {
  position: relative;
  min-height: 400px;
  background: linear-gradient(135deg, rgba(1, 42, 74, 0.02), rgba(2, 106, 186, 0.05));
  padding: 2rem;
  border-radius: 12px;
}

@keyframes feedFadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.social-feed {
  animation: feedFadeIn 0.5s ease-out forwards;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 600px;
  margin: 0 auto;
}

.instagram-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  max-width: 1200px;
  gap: 2rem;
}

.social-card {
  background: linear-gradient(135deg, white, rgba(2, 106, 186, 0.02));
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;
  border: 2px solid transparent;
  box-shadow: 0 4px 15px rgba(1, 42, 74, 0.05);
}

.social-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(2, 106, 186, 0.15);
  border-color: var(--primary-blue);
  background: linear-gradient(135deg, white, rgba(2, 106, 186, 0.08));
}

.social-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.social-card:hover .social-image {
  transform: scale(1.05);
}

.social-card .content {
  padding: 1.5rem;
  background: linear-gradient(to top, rgba(33, 150, 243, 0.1), transparent);
  transition: all 0.3s ease;
}

.social-card:hover .content {
  background: linear-gradient(to top, rgba(33, 150, 243, 0.2), transparent);
}

.social-stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  font-size: 0.875rem;
  color: var(--dark-gray);
}

.social-stats > div {
  display: flex;
  gap: 1rem;
}

/* Twitter Card Specific */
.twitter-card {
  padding: 1.5rem;
  background: linear-gradient(135deg, white, rgba(29, 161, 242, 0.05));
}

.twitter-card:hover {
  background: linear-gradient(135deg, white, rgba(29, 161, 242, 0.1));
}

.twitter-card p {
  margin-bottom: 1rem;
  line-height: 1.5;
}

/* Facebook Card Specific */
.facebook-card {
  background: linear-gradient(135deg, white, rgba(66, 103, 178, 0.05));
}

.facebook-card:hover {
  background: linear-gradient(135deg, white, rgba(66, 103, 178, 0.1));
}

.facebook-card .content {
  border-top: 1px solid rgba(33, 150, 243, 0.1);
}

.facebook-card p {
  margin-bottom: 1rem;
  line-height: 1.5;
}

/* Instagram Card Specific */
.instagram-card {
  background: linear-gradient(135deg, white, rgba(225, 48, 108, 0.05));
}

.instagram-card:hover {
  background: linear-gradient(135deg, white, rgba(225, 48, 108, 0.1));
}

.instagram-card .content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.2), transparent);
  color: white;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease;
  padding: 2rem 1.5rem;
}

.instagram-card:hover .content {
  opacity: 1;
  transform: translateY(0);
}

.instagram-card .social-stats {
  color: white;
}

@media (max-width: 768px) {
  .social-tabs {
    padding: 0 1rem;
  }

  .instagram-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 0 1rem;
  }

  .social-card {
    margin-bottom: 1rem;
  }

  .social-feed {
    padding: 0 1rem;
  }
}

/* Press Section Styles */
.press-container {
  display: grid;
  grid-template-columns: 1fr 280px;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.section-title {
  color: var(--primary-blue);
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.press-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
}

.press-card {
  background: var(--white);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid transparent;
  font-size: 0.95rem;
}

.press-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(1, 42, 74, 0.15);
  border-color: var(--primary-blue);
}

.press-image-container {
  position: relative;
  height: 160px;
}

.press-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.press-source {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  background: var(--primary-blue);
  color: var(--white);
  padding: 0.35rem 0.75rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
}

.press-content {
  padding: 1.25rem;
}

.press-content h3 {
  color: var(--primary-blue);
  margin-bottom: 0.75rem;
  font-size: 1.1rem;
  line-height: 1.4;
}

.press-content p {
  color: var(--dark-gray);
  margin-bottom: 1rem;
  line-height: 1.5;
  font-size: 0.9rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.press-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.75rem;
  border-top: 1px solid rgba(1, 42, 74, 0.1);
}

.press-date {
  color: var(--dark-gray);
  font-size: 0.8rem;
}

.press-read-more {
  background: none;
  border: none;
  color: var(--primary-blue);
  font-weight: 500;
  cursor: pointer;
  transition: transform 0.3s ease;
  font-size: 0.9rem;
}

.press-read-more:hover {
  transform: translateX(5px);
}

/* Popular Posts Styles */
.press-sidebar {
  background: var(--white);
  border-radius: 8px;
  padding: 1.25rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  height: fit-content;
}

.popular-posts {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.popular-post-card {
  display: flex;
  gap: 0.75rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid rgba(1, 42, 74, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease;
}

.popular-post-card:hover {
  transform: translateX(5px);
}

.popular-post-card img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 4px;
}

.popular-post-content h3 {
  font-size: 0.95rem;
  color: var(--primary-blue);
  margin-bottom: 0.5rem;
  line-height: 1.4;
}

.popular-post-meta {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  font-size: 0.75rem;
  color: var(--dark-gray);
}

@media (max-width: 1024px) {
  .press-container {
    grid-template-columns: 1fr;
  }

  .press-sidebar {
    max-width: 600px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .press-grid {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    padding: 0 1rem;
  }

  .press-container {
    padding: 0 1rem;
  }

  .popular-post-card {
    padding: 0.75rem;
  }
}

@media (max-width: 480px) {
  .press-grid {
    grid-template-columns: 1fr;
  }
} 