:root {
  --primary-blue: #012A4A;
  --secondary-blue: #013A63;
  --white: #ffffff;
  --light-gray: #f5f5f5;
  --dark-gray: #333333;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
  color: var(--dark-gray);
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Navbar Styles */
.navbar {
  background-color: var(--primary-blue);
  padding: 1rem 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.logo a {
  color: var(--white);
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-links {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.nav-links a {
  color: var(--white);
  text-decoration: none;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.nav-links a:hover {
  color: var(--primary-blue);
  background-color: var(--white);
}

/* Active NavLink styles */
.nav-links a.active {
  color: var(--primary-blue);
  background-color: var(--white);
}

.logo a.active {
  color: var(--white);
  font-weight: bold;
}

/* Menu Button Styles */
.menu-button {
  display: block;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  z-index: 1001;
  position: relative;
}

.menu-button span {
  display: block;
  width: 25px;
  height: 2px;
  background-color: var(--white);
  margin: 6px 0;
  transition: all 0.3s ease;
}

.menu-button.active span:nth-child(1) {
  transform: rotate(45deg) translate(6px, 6px);
}

.menu-button.active span:nth-child(2) {
  opacity: 0;
}

.menu-button.active span:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -6px);
}

/* Hamburger Menu Styles */
.hamburger-menu {
  position: fixed;
  top: 0;
  right: -100%;
  height: 100vh;
  width: 250px;
  background-color: var(--primary-blue);
  padding: 5rem 1rem 2rem;
  transition: right 0.3s ease;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  z-index: 1000;
  overflow-y: auto;
}

.menu-divider {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0.5rem 0;
  width: 100%;
}

.hamburger-menu.active {
  right: 0;
}

.hamburger-menu a {
  color: var(--white);
  text-decoration: none;
  font-weight: 500;
  padding: 1rem;
  border-radius: 4px;
  transition: all 0.3s ease;
  text-align: left;
}

.hamburger-menu a:hover {
  background-color: var(--secondary-blue);
}

.hamburger-menu a.active {
  background-color: var(--secondary-blue);
  color: var(--white);
}

.hamburger-menu a:last-child {
  border-bottom: none;
}

/* Overlay for hamburger menu */
.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 999;
}

.menu-overlay.active {
  opacity: 1;
  visibility: visible;
}

/* Footer Styles */
.footer {
  background-color: var(--dark-gray);
  color: var(--white);
  padding: 3rem 2rem 1rem;
  margin-top: auto;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  gap: 2rem;
}

.footer-section {
  flex: 1;
  min-width: 250px;
}

.footer-section h3 {
  color: var(--primary-blue);
  margin-bottom: 1rem;
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-links a {
  color: var(--white);
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: var(--primary-blue);
}

.footer-bottom {
  text-align: center;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

/* Common Page Styles */
.page-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.page-title {
  color: var(--primary-blue);
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
}

.section {
  background-color: var(--white);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .page-container {
    padding: 1rem;
  }

  .section {
    padding: 1.5rem;
  }

  .page-title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 768px) {
  .navbar {
    padding: 0.8rem;
  }

  .logo a {
    font-size: 1.3rem;
  }

  .nav-links {
    display: none;
  }

  .menu-button {
    display: block;
  }

  .hamburger-menu {
    width: 100%;
    max-width: none;
  }

  .hamburger-menu a {
    padding: 1rem;
    font-size: 1.1rem;
  }

  .page-container {
    margin: 1rem auto;
  }

  .page-title {
    font-size: 1.8rem;
    margin-bottom: 1.2rem;
  }

  .section {
    padding: 1rem;
    margin-bottom: 1rem;
  }

  /* Office Cards Responsive */
  .office-card {
    padding: 1.5rem;
  }

  .office-card h3 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  .office-card p {
    font-size: 1rem;
  }

  /* Footer Responsive */
  .footer {
    padding: 2rem 1rem 1rem;
  }

  .footer-content {
    gap: 1.5rem;
  }

  .footer-section {
    min-width: 100%;
    text-align: center;
  }

  .social-links {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 0.6rem;
  }

  .logo a {
    font-size: 1.2rem;
  }

  .menu-button span {
    width: 20px;
  }

  .page-title {
    font-size: 1.5rem;
  }

  .section {
    padding: 0.8rem;
  }

  /* Office Cards Extra Small Screen */
  .office-card {
    padding: 1rem;
  }

  .office-card h3 {
    font-size: 1.2rem;
  }

  .office-card p {
    font-size: 0.9rem;
  }
}

/* Modal Responsive Design */
@media (max-width: 768px) {
  .video-modal-content,
  .gallery-modal,
  .modal-content {
    width: 95%;
    margin: 1rem;
    padding: 1rem;
  }

  .modal-close-button {
    right: 0.5rem;
    top: 0.5rem;
  }
}

/* Navigation Responsive */
@media (max-width: 768px) {
  .sticky-nav {
    bottom: 0;
    top: auto;
    right: 0;
    left: 0;
    transform: none;
    flex-direction: row;
    justify-content: space-around;
    background: var(--primary-blue);
    padding: 0.5rem;
    border-radius: 0;
  }

  .sticky-nav-item {
    writing-mode: horizontal-tb;
    width: auto;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
  }
}

/* Grid Layouts Responsive */
@media (max-width: 1200px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}

/* Form Responsiveness */
@media (max-width: 768px) {
  input,
  textarea,
  select {
    font-size: 16px; /* Prevents zoom on iOS */
  }

  .form-grid {
    grid-template-columns: 1fr;
  }
}

/* Image and Video Responsiveness */
img,
video {
  max-width: 100%;
  height: auto;
}

/* Card Grid Responsiveness */
@media (max-width: 1200px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

@media (max-width: 768px) {
  .card-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

/* Button Responsiveness */
@media (max-width: 768px) {
  .button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
}

/* Container Padding */
@media (max-width: 768px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/* Typography Responsive */
@media (max-width: 768px) {
  h1 { font-size: 1.8rem; }
  h2 { font-size: 1.5rem; }
  h3 { font-size: 1.3rem; }
  h4 { font-size: 1.1rem; }
  p { font-size: 1rem; }
}

/* Add smooth scrolling for better mobile experience */
html {
  scroll-behavior: smooth;
}

/* Office Cards Styles */
.office-card {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(1, 42, 74, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
  border: 1px solid rgba(1, 42, 74, 0.1);
}

.office-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(1, 42, 74, 0.15);
  background: linear-gradient(135deg, var(--primary-blue), var(--secondary-blue));
  color: white;
}

.office-card h3 {
  color: var(--primary-blue);
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  border-bottom: 1px solid rgba(1, 42, 74, 0.1);
  padding-bottom: 0.75rem;
  transition: all 0.3s ease;
}

.office-card:hover h3 {
  color: white;
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.office-card strong {
  transition: all 0.3s ease;
}

.office-card:hover strong {
  color: rgba(255, 255, 255, 0.8);
}

/* Horizontal Timeline Styles */
.achievements-section {
  padding: 2rem 0;
  overflow-x: auto;
  margin: 2rem 0;
}

.timeline-horizontal {
  position: relative;
  display: flex;
  gap: 2rem;
  padding: 2rem 0;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.timeline-horizontal::-webkit-scrollbar {
  display: none;
}

.timeline-item-horizontal {
  flex: 0 0 300px;
  scroll-snap-align: center;
  position: relative;
  transition: all 0.5s ease;
}

.timeline-item-horizontal.active {
  z-index: 2;
}

.timeline-dot {
  width: 20px;
  height: 20px;
  background: var(--primary-blue);
  border-radius: 50%;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  transition: all 0.3s ease;
}

.timeline-item-horizontal.active .timeline-dot {
  transform: translateX(-50%) scale(1.2);
  box-shadow: 0 0 15px rgba(var(--primary-blue-rgb), 0.3);
}

.timeline-line {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(90deg, var(--primary-blue), var(--secondary-blue));
  z-index: 1;
}

.timeline-content-horizontal {
  padding-top: 2rem;
  text-align: center;
}

.timeline-year-horizontal {
  display: inline-block;
  padding: 0.5rem 1rem;
  background: linear-gradient(135deg, var(--primary-blue), var(--secondary-blue));
  color: white;
  border-radius: 20px;
  margin-bottom: 1rem;
  font-weight: 600;
}

.timeline-card {
  background: white;
  padding: 1.5rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.timeline-item-horizontal.active .timeline-card {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.timeline-card h3 {
  color: var(--primary-blue);
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

.timeline-card p {
  color: var(--dark-gray);
  font-size: 0.9rem;
  line-height: 1.5;
}

/* Timeline Responsive Design */
@media (max-width: 768px) {
  .achievements-section {
    padding: 1rem 0;
  }

  .timeline-horizontal {
    padding: 3rem 1rem;
  }

  .timeline-item-horizontal {
    flex: 0 0 250px;
  }

  .timeline-card {
    padding: 1rem;
  }

  .timeline-card h3 {
    font-size: 1.1rem;
  }

  .timeline-card p {
    font-size: 0.85rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
