.initiative-card {
  padding: 1.5rem;
  background-color: var(--light-gray);
  border-radius: 8px;
  transform: translateY(0);
  transition: all 0.3s ease;
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.initiative-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.15), 0 0 15px rgba(33, 150, 243, 0.5);
  background-color: white;
  border: 1px solid var(--primary-blue);
}

.initiative-card h3 {
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.stat-card {
  text-align: center;
  min-width: 200px;
  padding: 2rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.stat-card h3 {
  font-size: 2.5rem;
  color: var(--primary-blue);
  transition: color 0.3s ease;
}

.stat-card p {
  transition: color 0.3s ease;
}

.stat-card:hover {
  transform: scale(1.05);
  background-color: var(--primary-blue);
  box-shadow: 0 0 20px rgba(33, 150, 243, 0.5);
  border: 1px solid var(--primary-blue);
}

.stat-card:hover h3,
.stat-card:hover p {
  color: white;
}

.timeline-item {
  margin-bottom: 2rem;
  padding: 1rem 2rem;
  border-left: 3px solid var(--primary-blue);
  transition: all 0.3s ease;
  cursor: pointer;
  border-radius: 0 8px 8px 0;
}

.timeline-item:hover {
  transform: translateX(10px);
  background-color: rgba(33, 150, 243, 0.05);
  border-left: 6px solid var(--primary-blue);
  box-shadow: 0 0 15px rgba(33, 150, 243, 0.3);
}

/* Navigation Buttons */
.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 10;
}

.nav-button:hover {
  background: rgba(255, 255, 255, 0.9);
  transform: translateY(-50%) scale(1.1);
}

/* Impact Section Styles */
.impact-section {
  padding: 6rem 2rem;
  background-color: white;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.impact-container {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  gap: 4rem;
  align-items: center;
  padding: 2rem;
}

/* Circle Animations */
@keyframes pulseGlow {
  0% {
    box-shadow: 0 0 20px rgba(1, 42, 74, 0.2);
  }
  50% {
    box-shadow: 0 0 40px rgba(1, 42, 74, 0.4);
  }
  100% {
    box-shadow: 0 0 20px rgba(1, 42, 74, 0.2);
  }
}

@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Center Circle */
.center-circle {
  flex-shrink: 0;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(135deg, #012A4A, #013A63);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  animation: pulseGlow 3s infinite ease-in-out, floatAnimation 3s infinite ease-in-out;
}

.center-circle::after {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border-radius: 50%;
  border: 2px solid #012A4A;
  opacity: 0.2;
  animation: spin 20s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.circle-content {
  text-align: center;
  color: white;
  padding: 2rem;
  max-width: 80%;
  z-index: 1;
}

.circle-content h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  font-weight: 600;
  opacity: 0;
  animation: fadeInUp 0.8s forwards ease-out;
}

.circle-content p {
  font-size: 1.2rem;
  line-height: 1.5;
  opacity: 0;
  animation: fadeInUp 0.8s 0.3s forwards ease-out;
}

/* Impact Cards */
.impact-cards {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  align-content: center;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes borderTravel {
  0% {
    clip-path: inset(0 98% 98% 0);
  }
  25% {
    clip-path: inset(0 0 98% 0);
  }
  50% {
    clip-path: inset(0 0 0 98%);
  }
  75% {
    clip-path: inset(98% 0 0 0);
  }
  100% {
    clip-path: inset(0 98% 98% 0);
  }
}

.impact-card {
  background: white;
  padding: 1.5rem 2rem;
  border-radius: 20px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
  position: relative;
  opacity: 0;
  animation: fadeInUp 0.8s forwards ease-out;
  border: 2px solid #012A4A;
  overflow: hidden;
}

/* Add new glow animation */
@keyframes glowTravel {
  0% {
    background-position: -200% 5;
  }
  100% {
    background-position: 200% 0;
  }
}

.impact-card::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 20px;
  background: linear-gradient(90deg, 
    transparent 0%,
    rgba(1, 42, 74, 0.8) 25%,
    rgba(1, 42, 74, 0.8) 75%,
    transparent 100%
  );
  background-size: 200% 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  z-index: 1;
}

.impact-card:hover::before {
  opacity: 1;
  animation: glowTravel 2s linear infinite;
}

.impact-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(1, 42, 74, 0.15);
}

.impact-card-content {
  position: relative;
  z-index: 2;
}

.impact-card h3 {
  color: #012A4A;
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
  position: relative;
  padding-bottom: 0.5rem;
  transition: color 0.3s ease;
}

.impact-card:hover h3 {
  color: #012A4A;
  transform: translateY(-2px);
}

.impact-card h4 {
  color: #013A63;
  font-size: 1.2rem;
  margin-bottom: 0.75rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

.impact-card p {
  color: #666;
  font-size: 1rem;
  line-height: 1.6;
  transition: color 0.3s ease;
}

/* Add animation delay for cards */
.impact-card:nth-child(1) { animation-delay: 0.2s; }
.impact-card:nth-child(2) { animation-delay: 0.4s; }
.impact-card:nth-child(3) { animation-delay: 0.6s; }
.impact-card:nth-child(4) { animation-delay: 0.8s; }
.impact-card:nth-child(5) { animation-delay: 1s; }
.impact-card:nth-child(6) { animation-delay: 1.2s; }

/* Responsive Design */
@media (max-width: 1200px) {
  .impact-container {
    flex-direction: column;
    gap: 3rem;
  }

  .center-circle {
    width: 400px;
    height: 400px;
  }

  .circle-content h2 {
    font-size: 2.2rem;
  }

  .circle-content p {
    font-size: 1.1rem;
  }

  .impact-cards {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .impact-section {
    padding: 3rem 1rem;
  }

  .impact-container {
    padding: 1rem;
  }

  .center-circle {
    width: 300px;
    height: 300px;
  }

  .circle-content h2 {
    font-size: 2rem;
  }

  .impact-cards {
    grid-template-columns: 1fr;
  }
}

/* Stats Section */
.stats-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto 4rem;
  padding: 0 2rem;
}

.stat-card {
  background: white;
  padding: 2rem;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

.stat-card:hover {
  transform: translateY(-10px);
  border-color: #012A4A;
  box-shadow: 0 10px 30px rgba(1, 42, 74, 0.15);
}

.stat-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.stat-card h3 {
  color: #012A4A;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  font-weight: 700;
}

.stat-card p {
  color: #666;
  font-size: 1.1rem;
}

/* Achievements Timeline */
.achievements-section {
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 2rem;
}

.section-title {
  text-align: center;
  color: #012A4A;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  position: relative;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background: #012A4A;
}

.timeline {
  position: relative;
  padding: 2rem 0;
}

.timeline::before {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 100%;
  background: #012A4A;
}

.timeline-item {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  width: 100%;
  position: relative;
}

.timeline-year {
  background: #012A4A;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 30px;
  font-weight: 600;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.timeline-content {
  background: white;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  width: 45%;
  margin-top: 2rem;
  transition: all 0.3s ease;
  position: relative;
}

.timeline-item:nth-child(odd) .timeline-content {
  margin-right: 55%;
}

.timeline-item:nth-child(even) .timeline-content {
  margin-left: 55%;
}

.timeline-content:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(1, 42, 74, 0.15);
}

.timeline-content h3 {
  color: #012A4A;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.timeline-content p {
  color: #666;
  line-height: 1.6;
}

/* Testimonials Section */
.testimonials-section {
  background: linear-gradient(135deg, #012A4A, #013A63);
  padding: 4rem 2rem;
  margin: 4rem 0;
  color: white;
}

.testimonials-section .section-title {
  color: white;
}

.testimonials-section .section-title::after {
  background: white;
}

.testimonials-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.testimonial-card {
  background: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 20px;
  position: relative;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.15);
}

.quote-icon {
  font-size: 4rem;
  position: absolute;
  top: -1rem;
  left: 1rem;
  opacity: 0.2;
  font-family: serif;
}

.testimonial-quote {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;
}

.testimonial-author h4 {
  font-size: 1.1rem;
  margin-bottom: 0.25rem;
}

.testimonial-author p {
  font-size: 0.9rem;
  opacity: 0.8;
}

/* Call to Action Section */
.cta-section {
  display: flex;
  align-items: center;
  gap: 4rem;
  padding: 4rem;
  background: linear-gradient(135deg, rgba(1, 42, 74, 0.05), rgba(1, 42, 74, 0.1));
  border-radius: 20px 0 0 0;
  margin: 4rem auto 0;
  max-width: 100%;
}

.cta-content {
  flex: 1;
  text-align: left;
  padding-right: 2rem;
}

.cta-image {
  flex: 1;
  height: 400px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(1, 42, 74, 0.2);
}

.cta-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cta-section h2 {
  color: #012A4A;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: left;
}

.cta-section p {
  color: #666;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  text-align: left;
}

.cta-button {
  background: #012A4A;
  color: white;
  padding: 1rem 3rem;
  border: none;
  border-radius: 30px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
}

.cta-button:hover {
  background: #013A63;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(1, 42, 74, 0.3);
}

/* Responsive Design Updates */
@media (max-width: 1200px) {
  .stats-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .testimonials-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .stats-container {
    grid-template-columns: 1fr;
  }

  .timeline::before {
    left: 0;
  }

  .timeline-item {
    justify-content: flex-start;
  }

  .timeline-year {
    left: 0;
    transform: none;
  }

  .timeline-content {
    width: 90%;
    margin-left: 10% !important;
    margin-right: 0 !important;
  }

  .testimonials-container {
    grid-template-columns: 1fr;
  }

  .cta-section {
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;
  }

  .cta-content {
    padding-right: 0;
  }

  .cta-image {
    width: 100%;
    height: 300px;
  }
} 